.Documents-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--background-light);
  padding: 24px;
  border: 1px solid #1111111a;
  border-radius: 20px;
  gap: 24px;
  .row {
    .col {
      display: flex;
      justify-content: start;
      .info-field {
        width: 100%;
        font-weight: 700;
      }
      .upload {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .button-upload {
          padding: 12px;
          border-radius: 8px;
          width: 50%;
          border: 1px solid black;
          font-size: var(--fs-sm);
        }
        .button-upload:hover {
          background-color: var(--background-dark);
        }
      }
    }
  }
  .heading {
    border-bottom: 1px solid #1111111a;
    padding-bottom: 12px;
    h2 {
      font-size: var(--fs-m);
    }
  }
  .add-document {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #111111a6;
    padding: 20px;
    cursor: pointer;
    border-radius: 20px;
    font-size: 24px;
    transition: all ease 0.3s;
    &:hover {
      background-color: #00000023;
    }
  }
  button {
    border: 2px dashed;
    padding: 8px;
    width: 100%;
    border-radius: 20px;
    cursor: pointer;
    font-size: 32px;
    background-color: var(--background-light);
  }
}
.up-icons {
  // width: 100%;
  display: flex;
  position: absolute;
  right: 20px;
  flex-direction: row;
  gap: 4px;
  cursor: pointer;
}
