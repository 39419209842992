.ChatContainer-container {
  display: flex;
  // width: 400px;
  background-color: #fff;
  flex-direction: column;
  // height: 500px;
  box-shadow: 0px 10px 25px #473aa834;
  position: absolute;
  // right: 100px;
  // bottom: 30px;
  // edited
  width: 30%;
  height: 70vh;
  right: 4%;
  bottom: 4%;
  // edited

  gap: 12px;
  border-radius: 10px;
  padding: 12px;
  .main-container {
    display: flex;
    gap: 12px;
    padding: 16px 0;
    overflow-y: auto;
    background-color: #f5f5ff;
    flex-direction: column;
    box-shadow: inset 0px 5px 8px #473aa834;
    height: 100%;
    border-radius: 12px;
  }
  .input-container {
    width: 100%;
  }
}
// // edited
@media (max-width:768px){
 .ChatContainer-container {
  width: 70%;
  height: 50vh;
 }
}
// // edited for bigger screen 
// @media (max-width:1134px){
//   .ChatContainer-container{
//    width: 50%;
//    height: 50vh;
//   }

//  }
