.howmayihelpyou-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--background-light);
  padding: 24px;
  border: 1px solid #1111111a;
  border-radius: 20px;
  gap: 24px;

  .hwih-sub-headings{
    display: flex;
    justify-content: space-between;

  }
  .heading {
    border-bottom: 1px solid #1111111a;
    padding-bottom: 12px;
    font-size: smaller;
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .askHeading {
      text-align: center;
    }
    .row {
      display: flex;
      justify-content: start;
      .col {
        width: 100%;
        .info-fields {
          display: flex;
          width: 100%;
          p{
           font-weight : 0;
           display: flex;
           align-items: center;
           font-size: 0.8em;
           padding: 0.9em;
           padding-bottom: 0.9em;
           border-left: 1px solid #111111;
          }
        }
      }
    }
    .helpButton {
      width: 100%;
      margin-top: 100px;
      padding: 20px;
    }
  }
  .hwihu-edit{
   width: 100%;
   display: flex;
   // justify-content: space-between;
   gap: 1em;
   padding: 1em;

   button{
    padding: 0.7em 0.5em 0.7em 0.5em;
   }
  }
}
