.Comment-container {
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: start;
  .owner {
    display: flex;
    img {
      object-fit: contain;
      border-radius: 50%;
      cursor: pointer;
      -webkit-user-drag: none;
      width: 50px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: left;
    width: 100%;
    .top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .icons {
        display: flex;
        gap: 4px;
      }
      span {
        font-weight: 700;
        cursor: pointer;
      }
      .delete-icon {
        cursor: pointer;
        padding-right: 8px;
      }
    }
    p {
      width: 100%;
    }
  }
}
