.add-profile-pic-panel {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  z-index: 999;
}

.ProfilePic-container {
  position: relative;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 2px 2px 10px #1111111b;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-user-drag: none;
    border: 5px solid var(--text-light);
  }
  .pic-upload-container {
    cursor: pointer;
    position: absolute;
    top: 143px;
    right: 11px;
    display: flex;
    background-color: var(--text-light);
    border-radius: 13px;
    padding: 7px 12px;
    box-shadow: 2px 2px 10px #1111111b;
    .camera-icon {
      color: var(--text-dark);
      transition: all 0.3s ease;
      &:hover {
        color: var(--text-color);
      }
    }
  }

  input {
    display: none;
  }
}


// Media query for smaller screens
@media (max-width: 768px) {
 .ProfilePic-container {
   height: 150px; // Adjusted height for smaller screens
   width: 150px; // Adjusted width for smaller screens

   .pic-upload-container {
     top: 108px; // Adjusted position for smaller height
   }
 }
}