.Widget-container {
  background-color: var(--primary-dark);
  color: var(--primary-light);
  // padding: 2em 2em;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1em 2em ;
  border-radius: 20px;
  width: 30%;
  // box-shadow: 2px 2px 32px #1403845b;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
