.Payments-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .heading {
    background-color: var(--primary-light);
    padding: 20px 20px 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.068);
    h1 {
      padding: 16px;
      font-size: var(--fs-l);
    }
    .row {
      display: flex;
      padding: 20px;
      margin: 29px;
      text-align: start;
      justify-content: space-between;
      .pc-h4 h4 {
        font-size: var(--fs-table-heading);
      }
      .col {
        display: flex;
        justify-content: space-between;
        width: 100%;
        // font-size: 20px;
        font-size: var(--fs-sm);
        color: var(--text-dark);
      }
    }
  }
}
.sticky {
  position: sticky !important;
  top: 0;
}
.RecentPayments-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 30px;
  width: 100%;
  height: 100%;
  overflow: scroll;
  // box-shadow: rgba(200, 208, 231, 0.514) 3.2px 3.2px 8px 0px inset, rgba(255, 255, 255, 0.603) -3.2px -3.2px 8px 0px inset;
  border-radius: 20px;

  .table-headings {
    background-color: #f7f4ff;
    padding: 0;
  }
  .pagination {
    display: flex;
    justify-content: center;
  }
  .top {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .table {
    overflow: scroll;
    .row {
      display: flex;
      border-bottom: 1px solid rgba(24, 45, 107, 0.253);
      padding: 0px;
      margin: 29px;
      text-align: start;
      justify-content: space-between;
      .col {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 20px;
        color: var(--text-dark);
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .Payments-container {
    width: fit-content;
    overflow-y: scroll;
    .row {
      width: 700px;
    }
  }
}
