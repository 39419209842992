@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');


.qf-parent{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 30px 20px;
    .qf-child{
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 20px;
        padding: 20px;
        // background: #ffffff;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

        .question-heading{
            p{

                color: #11111193;
                font-size: 1.5em;
                font-weight: 400;
                font-family: "Open Sans", sans-serif !important;
                padding-bottom: 10px;
            }
        }
        .question-discussion{
            width: 100%;
            height: 90%;
            background: #e5ddfa00;
            overflow-y: scroll;
            padding: 20px;
            display: flex;
            flex-direction: column;
            // justify-content: flex-end;
            

            .resp-avatar{
                border-radius: 50px;
                width: 2rem;
                height: 2rem;
                img{
                    width: 2rem;
                    height: auto;
                    border-radius: 50px;
                }
            }
            p.resp-details{

                span{
                    font-weight: bold;
                }
            }

            .admin-response{
                display: flex;
                gap: 1em;
                width: 100%;
            }
            .user-response{
                display: flex;
                gap: 1em;
                width: 100%;
                justify-content: flex-end;

                .user-chat-block{
                    p{
                        text-align:right ;
                    }
                }

            }
            .resp-chat-block{
                width: 30%;
            }

        }
        .give-a-response{
            position: absolute;
            left: 5px;
            bottom: 5px;
            display: flex;
            gap: 1em;
            padding: 10px 10px 10px 10px;
            // height: fit-content;
            height: 10%;
            width: 99%;
            align-items: center;

            textarea{
                height: 100%;
                width: 100%;
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
                border-radius: 10px;
                padding: 8px;
                border: none;
                resize: none;
                overflow: hidden;

            }
            textarea:focus{
                outline: none;
            }
            textarea::placeholder {
                color: rgba(131, 128, 128, 0.685);
                opacity: 1; /* Firefox */
              }
              
              textarea::-ms-input-placeholder { /* Edge 12 -18 */
                color: rgba(131, 128, 128, 0.685);;
              }
            button{
                background-color: #1F0A68;
                height: 100%;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg{
                    color: #ffffff;
                }
            }
            button:hover{
                background: none;
                border: 2px solid #1F0A68;

                svg{
                    color: #1F0A68
                }
            }
        }
    }
}

@media only screen
and (max-width : 680px) {
    .qf-parent{
        .qf-child{
            padding: 5px;
            .question-discussion{
                display: flex;
                flex-direction: column;
                gap: 10px;
                .resp-chat-block{
                    width: 70%;

                    p{
                        font-size: 10px;
                    }
                }
            }
        }
    }
}