.CoverImage-container {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
  border: 5px solid #fff;
  border-radius: 20px;
  img {
    border-radius: 20px;
    object-fit: cover;
    width: 100%;
    -webkit-user-drag: none;
    height: 400px;
  }
}

.pic-upload-container {
  cursor: pointer;
  position: absolute;
  top: 353px;
  right: 9px;
  display: flex;
  background-color: var(--text-light);
  border-radius: 13px;
  padding: 7px 12px;
  box-shadow: 2px 2px 10px #1111111b;
  .camera-icon {
    color: var(--text-dark);
    transition: all 0.3s ease;
    &:hover {
      color: var(--text-color);
    }
  }
}
