.summary-dashboard {
  padding: 40px;
  gap: 40px;
  display: flex;
  flex-direction: column;
  width: 24vw;
  height: calc(100%);
  overflow-y: auto;
  overflow-x: hidden;
  border-left: 1px solid #11111126;
  .counsellor {
    width: 100%;
    img {
      border-radius: 20px;
    }
  }
}

.notifications-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: auto;
}

.sessions {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  overflow-y: auto !important;
  height: 370px;
}

.users {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  overflow-y: auto !important;
  height: 320px;
}
