.users-container {
  width: 100%;
  overflow-y: auto;
  .pagination{
    display: flex;
    justify-content: center;
  }
  .heading {
    background-color: var(--primary-light);
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    // box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.151);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.068);

    h1 {
      padding: 16px;
      font-size: var(--fs-l);
    }
    .row {
      display: flex;
      padding: 20px;
      margin: 29px;
      text-align: start;
      justify-content: space-between;
      .col {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 20px;
        color: var(--text-dark);
        h4{
         // font-size: var(--fs-m);
         font-weight: 500;
        }
      }
    }
  }
}
.sticky {
  position: sticky !important;
  top: 0;
}
.AllUsers-container {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 20px;
  width: 100%;
  // edited
  overflow: scroll;
  // box-shadow: rgba(200, 208, 231, 0.514) 3.2px 3.2px 8px 0px inset, rgba(255, 255, 255, 0.603) -3.2px -3.2px 8px 0px inset;
  border-radius: 20px;
  .top {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .table {
    .row {
      display: flex;
      border-bottom: 1px solid rgba(24, 45, 107, 0.253);
      padding: 10px;
      margin: 29px;
      text-align: start;
      justify-content: space-between;
      .col {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
       font-size: 15px;
        color: var(--text-dark);
        // edited
        padding-right: 15px;
        img {
          border-radius: 50%;
          width: 48px;
          -webkit-user-drag: none;
        }
      }
    }
  }
} 
// edited
@media only screen 
and (max-width :600px){
 .users-container{
  width: fit-content;
  // overflow-y: scroll;
  .row{
   width: 700px;
   .col{
    padding: 20px;
   }
  }
 }
}

