.RecentUser-container {
  display: flex;
  align-items: center;
  gap: 24px;
  img {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    -webkit-user-drag: none;
  }
}
