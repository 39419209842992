// .sessions-i {
//   margin: 0;
//   display: flex;
//   gap: 24px;
//   flex-direction: column;
//   position: relative;
//   padding: 20px;
//   border-radius: 20px;
//   box-shadow: 2px 2px 32px rgba(17, 0, 255, 0.055);
//   width: 350px;
//   height: 330px;
//   background-color: var(--background-light);
//   h2 {
//     font-size: var(--fs-m);
//   }
// }
// button {
//   cursor: pointer;
//   border-radius: 20px;
//   padding: 10px;
//   border: 0;
//   background-color: var(--background-dark);
//   transition: all 0.3s ease;

//   &:hover {
//     background-color: var(--primary-color);
//     color: var(--text-light);
//   }
// }

// input {
//   border: none;
// }

// .top {
//   display: flex;
//   align-items: center;
//   h2 {
//     color: var(--primary-color);
//   }

//   .menu-item {
//     display: flex;
//     gap: 10px;
//   }
// }

// form {
//   .edit-mode {
//     display: flex;
//     flex-direction: column;
//     gap: 20px;

//     div {
//       display: flex;
//       gap: 8px;
//     }
//   }
// }

// .edit-mode {
//   display: flex;
//   flex-direction: column;
//   height: 100%;
//   gap: 44px;

//   p {
//     font-size: 28px;
//   }
// }

// .middle {
//   color: var(--text-dark);
//   display: flex;
//   flex-direction: column;
//   gap: 4px;
// }

// .drop-down-button {
//   cursor: pointer;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 32px;
//   width: 32px;
//   border-radius: 20%;
//   position: absolute;
//   right: 16px;
//   top: 16px;
// }

// .display-active {
//   display: flex !important;
//   opacity: 1 !important;
//   transform: translateY(0) !important;
//   transition: all 0.3s cubic-bezier(0, 0.86, 0.18, 0.99) !important;
// }

// .drop-down-menu {
//   opacity: 0;
//   transform: translateY(-10px);
//   flex-direction: column;
//   padding: 8px;
//   margin: 0;
//   position: absolute;
//   right: 16px;
//   top: 48px;
//   border-radius: 10px;
//   background-color: var(--background-light);
//   box-shadow: 2px 2px 6px #1111113a;
//   transition: all 0.5s cubic-bezier(0, 0.86, 0.18, 0.99) !important;
//   .menu-item {
//     display: flex;
//     align-items: center;
//     gap: 8px;
//     padding: 4px;
//     margin: 0;
//     cursor: pointer;
//     border-radius: 5px;
//     &:hover {
//       background-color: var(--background-color);
//     }
//   }
// }

// .bottom {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   gap: 16px;
//   margin: 0;
// }

// .edit-mode-bottom {
//   display: flex;
//   justify-content: space-evenly;
//   gap: 16px;
//   max-width: 100%;
//   // height: 100%;
//   button {
//     width: 100%;
//   }
// }
// .truncate {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }

// @media only screen and (max-width: 500px) {
//   .sessions-i {
//     width: fit-content;
//     // padding:15px;
//     // gap: 0.4em;
//     td {
//       font-size: 10px;
//       gap: 20px;
//     }
//   }
//   .drop-down-button {
//     top: 50px;
//   }
// }

.sessions-i {
  margin: 0;
  display: flex;
  gap: 24px;
  flex-direction: column;
  position: relative;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 2px 2px 32px rgba(17, 0, 255, 0.055);
  width: 350px;
  height: 330px;
  background-color: var(--background-light);

  h2 {
    font-size: var(--fs-m);
  }
}

button {
  cursor: pointer;
  border-radius: 20px;
  padding: 10px;
  border: 0;
  background-color: var(--background-dark);
  transition: all 0.3s ease;

  &:hover {
    background-color: var(--primary-color);
    color: var(--text-light);
  }
}

input {
  border: none;
}

.top {
  display: flex;
  align-items: center;

  h2 {
    color: var(--primary-color);
  }

  .menu-item {
    display: flex;
    gap: 10px;
  }
}

form {
  .edit-mode {
    display: flex;
    flex-direction: column;
    gap: 20px;

    div {
      display: flex;
      gap: 8px;
    }
  }
}

.edit-mode {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 44px;

  p {
    font-size: 28px;
  }
}

.middle {
  color: var(--text-dark);
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.drop-down-button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 20%;
  position: absolute;
  right: 16px;
  top: 16px;
}

.display-active {
  display: flex !important;
  opacity: 1 !important;
  transform: translateY(0) !important;
  transition: all 0.3s cubic-bezier(0, 0.86, 0.18, 0.99) !important;
}

.drop-down-menu {
  opacity: 0;
  transform: translateY(-10px);
  flex-direction: column;
  padding: 8px;
  margin: 0;
  position: absolute;
  right: 16px;
  top: 48px;
  border-radius: 10px;
  background-color: var(--background-light);
  box-shadow: 2px 2px 6px #1111113a;
  transition: all 0.5s cubic-bezier(0, 0.86, 0.18, 0.99) !important;

  .menu-item {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px;
    margin: 0;
    cursor: pointer;
    border-radius: 5px;

    &:hover {
      background-color: var(--background-color);
    }
  }
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  margin: 0;
}

.edit-mode-bottom {
  display: flex;
  justify-content: space-evenly;
  gap: 16px;
  max-width: 100%;

  button {
    width: 100%;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  /* Allow the text to wrap */
  word-wrap: break-word;
  /* Ensure long words also wrap correctly */
}

@media only screen and (max-width: 500px) {
  .sessions-i {
    width: fit-content;

    td {
      font-size: 10px;
      gap: 20px;
    }
  }

  .drop-down-button {
    top: 50px;
  }
}