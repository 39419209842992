.session {
  display: flex;
  flex-direction: column;
  width: 100%;
  .session-header {
    // display: flex;
    // // justify-content: space-between;
    padding: 20px;
    width: 100%;
    .left {
      .reset-changes {
        display: flex;
        justify-content: space-between;
        h1 {
          font-size: var(--fs-m);
        }
        button {
          border: none;
          background-color: var(--primary-dark);
          color: var(--background-light);
          padding: 8px 16px;
          font-size: 12px;
          font-weight: 600;
          cursor: pointer;
          border-radius: 20px;
          // edited
          height: 100%;
        }
      }
    }
  }
  .sessionContainer {
    flex: 6;
    overflow-y: auto;
  }
  .sessionList {
    display: flex;
    justify-content: start;
    gap: 2em;
    padding: 3em;
    flex-wrap: wrap;
  }
  .addSession {
    display: flex;
    flex-direction: row-reverse;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    button {
      padding: 10px;
      cursor: pointer;
      background-color: blue;
      color: white;
      border: none;
      border-radius: 10px;
      margin-left: 10px;
      &:hover {
        background-color: black;
      }
    }
    .sessionSort {
      .SortDropdown {
        padding: 8px;
        border-radius: 9px;
        margin-left: 8px;
        font-weight: 600;
      }
    }
  }

  .add-session-button {
    width: 200px;
    border-radius: 50px;
  }

  span.fd-toggle-btn {
    background-color: unset;
  }
  .fd-toggle-btn.active {
    border: 1px solid #11111131;
    padding: 0.3em;
    border-radius: 5px;
  }
  .filter-dropdown-main {
    width: 80%;
    height: fit-content;
    position: relative;
    border-radius: 10px;
    padding: 1em;
    background-color: rgba(200, 200, 236, 0.212);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.151);
    .filter-dropdown-sub {
      width: 100%;
      height: 100%;
      .fd-reset-btn {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .session {
    .session-header {
      .left {
        .reset-changes {
          flex-direction: column;
          // gap: 2em;
          padding-right: 1em;
        }
      }
    }
  }
}
