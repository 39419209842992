.ask-question-main {
 width: 100%;
 height: 100%;
 display: flex;
 align-items: center;
 justify-content: center;
 .ask-question-containers {
     background-color: white; 
     border-radius: 10px;
     padding: 20px 10px 20px 10px;
     box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
     display: flex;
     flex-direction: row;
     align-items: center;
     gap: 8px;

     .Ask_Ques_img {
         img {
         width: 100%;
         max-width: 300px;
         }
     }

     .ask_question_text {
         display: flex;
         flex-direction: column;
         gap: 10px; /* Adjust the gap between elements */
         width: 260px;
          word-wrap: break-word;

         h4 {
             margin: 0; /* Remove default margin */
         }

         .ask-question-dropdown {
             select {
                 width: 100%;
                 padding: 10px;
                 border: 1px solid #1111116e;
             }
         }

         .ask-question-textarea {
             textarea {
                 width: 100%;
                 resize: none;
                 padding: 12px;
                 border: 1px solid #1111116e;
                 height: 200px; /* Set a fixed height for the textarea or adjust as needed */
             }
         }
.btn-container{
 display: flex;
 justify-content: space-evenly;
 .ask-question-btn-cancel{
 button{
  padding: 5px 14px;
 }
 }
             button {
                 background-color: var(--primary-color);
                 color: var(--background-light);
                 padding: 5px 20px;
                 border-radius: 10px;
                 transition: all 0.3s ease;
                 cursor: pointer;
                 // width: 50%;
             }         
        }
     }
 }
}

@media (max-width:768px){
 .ask-question-main{
  width: 80%;
 }
 // .ask-question-containers{
 //  // display: flex;
 //  width: fit-content;
 //  flex-wrap: wrap;
 // }
}