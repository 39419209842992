.RecentPayments-container {
  display: flex;
  flex-direction: column;



  .see-all-button {
    font-weight: 800;
    color: var(--primary-dark);
    cursor: pointer;
    display: flex;
    justify-content: end;
    font-size: var(--fs-sm) ;
  }
  .payments-top {
    display: flex;
    justify-content: space-between;
    align-items: end;
  }
  .payments-table p{
   font-size: var(--fs-sm);
  }
  .table {
    .row {
      display: flex;
      border-bottom: 1px solid rgba(24, 45, 107, 0.158);
      padding: 20px;
      margin: 29px;
      text-align: start;
      justify-content: space-between;
      .col {
        display: flex;
        width: 100%;
        font-size: var(--fs-sm);
        color: var(--text-dark);
      }
    }
  }
}
