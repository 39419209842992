.Feedbacks-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 24px;

  .feedbacks {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 50vw;
    align-items: center;
  }

  .pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    .pagination-arrow {
      cursor: pointer;
      font-size: 24px;
      margin: 0 10px;
      user-select: none;
      transition: color 0.3s ease;

      &:hover {
        cursor: pointer; // Add pointer cursor on hover
      }

      &.disabled {
        color: #ccc;
        cursor: not-allowed;
      }
    }

    span {
      font-size: 18px;
    }
  }
}
